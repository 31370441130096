'use client';

import React from 'react';
import { Box } from '@mui/material';
// import { Box, Typography, Paper, Grid } from '@mui/material';
// import { ResponsiveLine } from '@nivo/line';

export default function HomePage() {
  return (
    <Box sx={{ flexGrow: 1, ml: 8 }}>
      {/* <Typography variant="h6" component="h2" sx={{ mb: 4 }}>
        Processing Queues
      </Typography>
      <Grid container spacing={2} sx={{ mb: 8 }}>
        {["Videos", "Frames", "Electors", "Review"].map((item, index) => (
          <Grid item xs={3} key={index}>
            <Paper
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: 2,
                backgroundColor: "grey.200",
                borderRadius: 2,
              }}
            >
              <Typography variant="h4">
                {[18, 367, 1876, 456][index]}
              </Typography>
              <Typography variant="caption" sx={{ color: "grey.600" }}>
                {item}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Typography variant="h6" component="h2" sx={{ mb: 4 }}>
        Throughput
      </Typography>
      <Grid container spacing={2}>
        {["FPS", "Vote Age", "Pending Review"].map((item, index) => (
          <Grid item xs={4} key={index}>
            <Paper
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                p: 2,
                backgroundColor: "grey.200",
                borderRadius: 2,
              }}
            >
              <LineChart style={{ width: "100%", height: "200px" }} />
              <Typography variant="caption" sx={{ mt: 2, color: "grey.600" }}>
                {item}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid> */}
    </Box>
  );
}

// function LineChart(props: any) {
//   return (
//     <div {...props}>
//       <ResponsiveLine
//         data={[
//           {
//             id: 'C',
//             data: [
//               {
//                 x: 1,
//                 y: 283,
//               },
//               {
//                 x: 2,
//                 y: 250,
//               },
//               {
//                 x: 3,
//                 y: 152,
//               },
//               {
//                 x: 4,
//                 y: 294,
//               },
//               {
//                 x: 5,
//                 y: 211,
//               },
//               {
//                 x: 6,
//                 y: 172,
//               },
//               {
//                 x: 7,
//                 y: 24,
//               },
//               {
//                 x: 8,
//                 y: 25,
//               },
//               {
//                 x: 9,
//                 y: 76,
//               },
//               {
//                 x: 10,
//                 y: 5,
//               },
//             ],
//           },
//           {
//             id: 'B',
//             data: [
//               {
//                 x: 1,
//                 y: 43,
//               },
//               {
//                 x: 2,
//                 y: 237,
//               },
//               {
//                 x: 3,
//                 y: 21,
//               },
//               {
//                 x: 4,
//                 y: 35,
//               },
//               {
//                 x: 5,
//                 y: 26,
//               },
//               {
//                 x: 6,
//                 y: 154,
//               },
//               {
//                 x: 7,
//                 y: 181,
//               },
//               {
//                 x: 8,
//                 y: 65,
//               },
//               {
//                 x: 9,
//                 y: 261,
//               },
//               {
//                 x: 10,
//                 y: 199,
//               },
//             ],
//           },
//           {
//             id: 'A',
//             data: [
//               {
//                 x: 1,
//                 y: 240,
//               },
//               {
//                 x: 2,
//                 y: 228,
//               },
//               {
//                 x: 3,
//                 y: 77,
//               },
//               {
//                 x: 4,
//                 y: 178,
//               },
//               {
//                 x: 5,
//                 y: 196,
//               },
//               {
//                 x: 6,
//                 y: 204,
//               },
//               {
//                 x: 7,
//                 y: 153,
//               },
//               {
//                 x: 8,
//                 y: 289,
//               },
//               {
//                 x: 9,
//                 y: 250,
//               },
//               {
//                 x: 10,
//                 y: 274,
//               },
//             ],
//           },
//         ]}
//         enableCrosshair={false}
//         margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
//         xScale={{ type: 'point' }}
//         yScale={{
//           type: 'linear',
//           min: 0,
//           max: 'auto',
//         }}
//         axisTop={null}
//         axisRight={null}
//         axisBottom={{
//           tickSize: 5,
//           tickPadding: 5,
//           tickRotation: 0,
//           legend: 'X',
//           legendOffset: 45,
//           legendPosition: 'middle',
//         }}
//         axisLeft={{
//           tickSize: 5,
//           tickPadding: 5,
//           tickRotation: 0,
//           legend: 'Y',
//           legendOffset: -45,
//           legendPosition: 'middle',
//         }}
//         colors={{ scheme: 'paired' }}
//         pointSize={5}
//         pointColor={{
//           from: 'color',
//           modifiers: [['darker', 0.2]],
//         }}
//         pointBorderWidth={2}
//         pointBorderColor={{
//           from: 'color',
//           modifiers: [['darker', 0.2]],
//         }}
//         pointLabelYOffset={-12}
//         useMesh={true}
//         legends={[
//           {
//             anchor: 'bottom-right',
//             direction: 'column',
//             justify: false,
//             translateX: 100,
//             translateY: 0,
//             itemsSpacing: 0,
//             itemDirection: 'left-to-right',
//             itemWidth: 80,
//             itemHeight: 20,
//             symbolSize: 14,
//             symbolShape: 'circle',
//           },
//         ]}
//         theme={{
//           tooltip: {
//             container: {
//               fontSize: '12px',
//             },
//           },
//         }}
//         role="application"
//       />
//     </div>
//   );
// }
